import isServer from 'lib/is-server';

export const getVariable = (variable, defaultValue = null) => {
  const value = isServer() ? process.env[variable] : window[variable];
  return value || defaultValue;
};

export const appDomain = () => {
  return getVariable('APP_DOMAIN', 'www.pitchero.com').toLowerCase();
};

export const pitcheroDomain = () => {
  return getVariable('PITCHERO_DOMAIN');
};

export const marketingAssestsDomain = () => {
  return getVariable('MARKETING_ASSETS_DOMAIN', 'www.pitchero.com');
};

export const environment = () => {
  return getVariable('NODE_ENV');
};

export const isProduction = () => {
  return environment() === 'production';
};

export const mapBoxKey = () => {
  return getVariable('MAPBOX_API_KEY');
};

export const recaptchaV3Key = () => {
  return getVariable('GOOGLE_RECAPTCHA_V3_PUBLIC_KEY');
};
