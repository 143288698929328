const activityStreamTypePattern = '(album|news|club_event|match_preview|match_report|video)';
const profileTypePattern = '(coach|player)';

/**
 *  This matches things like "2019-01" and "2019-12" but not "2019-13". Tested here
 *  http://forbeslindesay.github.io/express-route-tester/
 */
const YEAR_MONTH_REGEX = `[0-9]{4}-0[1-9]|[0-9]{4}-10|[0-9]{4}-11|[0-9]{4}-12`;

module.exports = [
  {
    name: 'homepage',
    pattern: '/',
    page: 'homepage/index',
  },
  {
    name: 'news',
    pattern: '/news',
    page: 'news/index',
  },
  {
    name: 'news.team',
    pattern: '/news/team-:teamId([0-9]+)',
    page: 'news/index',
  },
  {
    name: 'news.team.category',
    pattern: `/news/team-:teamId([0-9]+)/:category${activityStreamTypePattern}`,
    page: 'news/index',
  },
  {
    name: 'news.team.month',
    pattern: `/news/team-:teamId([0-9]+)/:month(${YEAR_MONTH_REGEX})`,
    page: 'news/index',
  },
  {
    name: 'news.team.category.month',
    pattern: `/news/team-:teamId([0-9]+)/:category${activityStreamTypePattern}/:month(${YEAR_MONTH_REGEX})`,
    page: 'news/index',
  },
  {
    name: 'news.category',
    pattern: `/news/:category${activityStreamTypePattern}`,
    page: 'news/index',
  },
  {
    name: 'news.month',
    pattern: `/news/:month(${YEAR_MONTH_REGEX})`,
    page: 'news/index',
  },
  {
    name: 'news.category.month',
    pattern: `/news/:category${activityStreamTypePattern}/:month(${YEAR_MONTH_REGEX})`,
    page: 'news/index',
  },
  {
    name: 'news.article',
    pattern: '/news/:_title([a-z0-9-]+)-:id([0-9]+).html',
    page: 'news/article',
  },
  {
    name: 'team.news.article',
    pattern: '/teams/:teamId([0-9]+)/news/:_title([a-z0-9-]+)-:id([0-9]+).html',
    page: 'news/article',
  },
  {
    name: 'news.sponsored',
    pattern: '/news/sponsored/:_title([a-z0-9-]+)-:id([0-9]+).html',
    page: 'news/sponsored',
  },
  {
    name: 'calendar',
    pattern: '/calendar',
    page: 'calendar/index',
  },
  {
    name: 'calendar.month',
    pattern: '/calendar/:year([0-9]{4})/:month([1-9]{1}|1[0-2]{1})',
    page: 'calendar/index',
  },
  {
    name: 'calendar.event',
    pattern: '/calendar/event/:_title([a-z0-9-]+)-:id([0-9]+)',
    page: 'calendar/event',
  },
  {
    name: 'calendar.training',
    pattern: '/calendar/training/session-:id([0-9]+)',
    page: 'calendar/training',
  },
  {
    name: 'login',
    pattern: '/login',
    page: 'auth/login',
  },
  {
    name: 'join',
    pattern: '/signup',
    page: 'auth/signup',
  },
  {
    name: 'password_reset',
    pattern: '/password-reset',
    page: 'auth/password-reset',
  },
  {
    name: 'team.list',
    pattern: '/teams',
    page: 'team/list',
  },
  {
    name: 'team.homepage',
    pattern: '/teams/:teamId',
    page: 'team/homepage',
  },
  {
    name: 'team.fixtures',
    pattern: '/teams/:teamId([0-9]+)/fixtures-results',
    page: 'team/fixtures',
  },
  {
    name: 'team.league_table',
    pattern: '/teams/:teamId([0-9]+)/league-table',
    page: 'team/league-table',
  },
  {
    name: 'team.squad',
    pattern: '/teams/:teamId([0-9]+)/the-team',
    page: 'team/squad',
  },
  {
    name: 'team.squad.profile',
    pattern: `/teams/:teamId([0-9]+)/:profileType${profileTypePattern}/:_profile([a-z0-9-]+)-:profileId([0-9]+)/:seasonId([0-9]+)?`,
    page: 'team/profile',
  },
  {
    name: 'team.statistics',
    pattern: '/teams/:teamId([0-9]+)/statistics',
    page: 'team/statistics',
  },
  {
    name: 'team.reports',
    pattern: '/teams/:teamId([0-9]+)/match-reports',
    page: 'team/reports',
  },
  {
    name: 'team.match_centre.overview',
    pattern: '/teams/:teamId([0-9]+)/match-centre/:fixtureId([0-2]-[0-9]+)',
    page: 'team/match-centre',
  },
  {
    name: 'team.match_centre.report',
    pattern: '/teams/:teamId([0-9]+)/match-centre/:fixtureId([0-2]-[0-9]+)/report',
    page: 'team/match-centre',
  },
  {
    name: 'team.match_centre.events',
    pattern: '/teams/:teamId([0-9]+)/match-centre/:fixtureId([0-2]-[0-9]+)/events',
    page: 'team/match-centre',
  },
  {
    name: 'team.match_centre.scorecard',
    pattern: '/teams/:teamId([0-9]+)/match-centre/:fixtureId([0-2]-[0-9]+)/scorecard',
    page: 'team/match-centre',
  },
  {
    name: 'team.match_centre.lineup',
    pattern: '/teams/:teamId([0-9]+)/match-centre/:fixtureId([0-2]-[0-9]+)/lineup',
    page: 'team/match-centre',
  },
  {
    name: 'team.match_centre.table',
    pattern: '/teams/:teamId([0-9]+)/match-centre/:fixtureId([0-2]-[0-9]+)/table',
    page: 'team/match-centre',
  },
  {
    name: 'team.match_centre.photos',
    pattern: '/teams/:teamId([0-9]+)/match-centre/:fixtureId([0-2]-[0-9]+)/photos',
    page: 'team/match-centre',
  },
  {
    name: 'team.match_centre.photos.image',
    pattern:
      '/teams/:teamId([0-9]+)/match-centre/:fixtureId([0-2]-[0-9]+)/photos/:albumId([0-9]+)-:imageId([0-9]+)',
    page: 'team/match-centre',
  },
  {
    name: 'shop',
    pattern: '/payments',
    page: 'shop/index',
  },
  {
    name: 'shop.category',
    pattern: '/payments/category/:category([a-z]+)',
    page: 'shop/category',
  },
  {
    name: 'shop.product',
    pattern: '/payments/:_name([a-z0-9-]+)-:id([0-9]+).html',
    page: 'shop/product',
  },
  {
    name: 'media.albums',
    pattern: '/photos',
    page: 'media/media-list',
  },
  {
    name: 'media.albums.team',
    pattern: '/photos/team-:teamId([0-9]+)',
    page: 'media/media-list',
  },
  {
    name: 'media.album',
    pattern: '/photos/:_title([a-z0-9-]+)-:id([0-9]+).html',
    page: 'media/album',
  },
  {
    name: 'media.image',
    pattern: '/photos/:id([0-9]+)/:imageId([0-9]+).html',
    page: 'media/album',
  },
  {
    name: 'media.videos',
    pattern: '/videos',
    page: 'media/media-list',
  },
  {
    name: 'media.videos.team',
    pattern: '/videos/team-:teamId([0-9]+)',
    page: 'media/media-list',
  },
  {
    name: 'media.video',
    pattern: '/videos/:_title([a-z0-9-]+)-:id([0-9]+).html',
    page: 'media/video',
  },
  {
    name: 'contact',
    pattern: '/contact',
    page: 'contact',
  },
  {
    name: 'contact.official',
    pattern: '/contact/contact-official-:officialId([0-9]+)',
    page: 'contact',
  },
  {
    name: 'information.index',
    pattern: '/information',
    page: 'information/index',
  },
  {
    name: 'information.article',
    pattern: '/a/:_name([a-z0-9-]+)-:articleId([0-9]+).html',
    page: 'information/index',
  },
  {
    name: 'information.links',
    pattern: '/d/links.html',
    page: 'information/index',
  },
  {
    name: 'information.sponsors',
    pattern: '/d/sponsors.html',
    page: 'information/index',
  },
  {
    name: 'information.documents',
    pattern: '/d/documents.html',
    page: 'information/index',
  },
  {
    name: 'information.policies',
    pattern: '/policies',
    page: 'information/index',
  },
  {
    name: 'style.guide',
    pattern: '/style-guide',
    page: 'style-guide/index',
  },
  {
    name: 'account.membership.roles',
    pattern: '/account/membership/:memberId([0-9]+)/roles/',
    page: 'account/index',
  },
  {
    name: 'account.messages',
    pattern: '/account/messages',
  },
  {
    name: 'vanarama',
    pattern: '/vanarama',
    page: 'vanarama/index',
  },
  {
    name: 'bookings',
    pattern: '/bookings',
    page: 'bookings/index',
  },
];
