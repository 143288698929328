import { pathToRegexp } from 'path-to-regexp';
import { parse } from 'url';
import NextRouter from 'next/router';
import { appDomain } from '../env';
import Route from './route';

class ClubRouter {
  constructor(applicationDomain = null) {
    this.routes = [];
    this.clubsFolderRegex = pathToRegexp('/clubs/:folder([a-zA-Z0-9_-]+)(.*)');
    this.appDomain = applicationDomain || appDomain();
    // Set like this so it can be replaced with a mock in tests for pushClubRoute and replaceClubRoute
    this.router = NextRouter;
  }

  add(options) {
    const { name } = options;

    if (this.findByName(name)) {
      throw new Error(`Route "${name}" already exists`);
    }

    this.routes.push(new Route(options));
    return this;
  }

  findByName(name) {
    if (name) {
      return this.routes.filter((route) => route.name === name)[0];
    }

    return undefined;
  }

  stripClubsFolderPath(pathname) {
    const folderPatternMatch = this.clubsFolderRegex.exec(pathname);
    if (!folderPatternMatch) {
      return pathname;
    }

    const folder = folderPatternMatch[1];
    const prefix = `/clubs/${folder}`;
    const stripped = pathname.substr(prefix.length);
    return stripped.charAt(0) === '/' ? stripped : `/${stripped}`;
  }

  match(url, hostname) {
    const parsedUrl = parse(url, true);
    const { query } = parsedUrl;
    let { pathname } = parsedUrl;
    if (hostname === this.appDomain) {
      // If we are on the app domain and serving a club website under `/clubs/{folder}/` we strip that bit out before doing the matching
      // e.g. `/clubs/averagejoes/news` becomes `/news`
      pathname = this.stripClubsFolderPath(parsedUrl.pathname);
    }

    const matchedRoute = this.routes.find((route) => {
      return !!route.match(pathname);
    });

    if (!matchedRoute) {
      return { query, parsedUrl };
    }

    const params = matchedRoute.match(pathname);
    return { route: matchedRoute, params, query: { ...query, ...params }, parsedUrl };
  }

  findAndGetUrls(nameOrUrl, params) {
    const route = this.findByName(nameOrUrl);

    if (route) {
      return { route, urls: route.getUrls(params), byName: true };
    }

    const { route: matchedRoute, query } = this.match(nameOrUrl);
    const href = matchedRoute ? matchedRoute.getHref(query) : nameOrUrl;
    const urls = { href, as: nameOrUrl };
    return { route, urls };
  }

  clubRoute(club, name, params) {
    const route = this.findByName(name);
    const urls = route.getUrls(params);

    if (!club.externalDomain) {
      urls.as = `/clubs/${club.folder}${urls.as}`;
    }

    return { route, urls };
  }

  getRequestHandler(app, customHandler) {
    const nextHandler = app.getRequestHandler();

    return (req, res) => {
      // Ensure any double slashes at the start of the path get stripped via a redirect.
      // e.g. `//calendar` becomes `/calendar`
      if (req.originalUrl.startsWith('//')) {
        res.redirect(308, req.originalUrl.substr(1));
      }

      const { route, query, parsedUrl } = this.match(req.url, req.hostname);

      if (route) {
        if (customHandler) {
          customHandler({ req, res, route, query });
        } else {
          app.render(req, res, route.page, query);
        }
      } else {
        nextHandler(req, res, parsedUrl);
      }
    };
  }

  pushClubRoute(club, name, params, options) {
    const {
      urls: { as, href },
    } = this.clubRoute(club, name, params);
    return this.router.push(href, as, options);
  }

  replaceClubRoute(club, name, params, options) {
    const {
      urls: { as, href },
    } = this.clubRoute(club, name, params);
    return this.router.replace(href, as, options);
  }
}

export default ClubRouter;
