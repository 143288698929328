import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClubRouter from 'lib/routing';
import sanitizeParams from 'lib/routing/sanitize-params';
import { isProduction, appDomain } from 'lib/env';
import { getCurrentClub } from 'store/clubs/selectors';
import { getHost } from 'store/common/selectors';
import NextLink from 'next/link';
import AppInterceptedLink from '../app-intercepted-link';

const ClubLink = (props) => {
  const {
    route,
    params,
    club,
    currentClub,
    currentDomain,
    children,
    replace,
    scroll,
    hash,
    forceReload,
  } = props;
  const cleanedParams = sanitizeParams(params);

  const devMode = !isProduction();
  const clubLinkingTo = club || currentClub;
  const isSwitchingClub = clubLinkingTo.folder !== currentClub.folder;

  // When not in production all clubs are servers on /clubs/{folder}/
  const useCustomDomain = clubLinkingTo.externalDomain && !devMode;
  const linkDomain = useCustomDomain ? clubLinkingTo.externalDomain : appDomain();
  const child = Children.only(children);
  const passHref = child.type === 'a' || child.type === AppInterceptedLink;

  const linkProps = {
    children,
    replace,
    scroll,
    passHref,
  };
  // Use ClubRouter to convert route name to as & href
  Object.assign(linkProps, ClubRouter.findAndGetUrls(route, cleanedParams).urls);

  if (!useCustomDomain) {
    // Prefix path with the clubs folder
    linkProps.as = `/clubs/${clubLinkingTo.folder}${linkProps.as}`;
  }

  if (linkDomain.toLowerCase() !== currentDomain.toLowerCase() || isSwitchingClub) {
    // This forces a browser page load, this is key to ensure the currentClub is reloaded
    linkProps.as = `//${linkDomain}${linkProps.as}`;
    linkProps.href = `//${linkDomain}${linkProps.href}`;
  }

  if (hash) {
    linkProps.as += `#${hash}`;
    linkProps.href += `#${hash}`;
  }

  if (forceReload) {
    return <a href={linkProps.as}>{children}</a>;
  }

  return <NextLink {...linkProps} />;
};

ClubLink.defaultProps = {
  params: {},
  club: null,
  replace: null,
  scroll: null,
  hash: null,
  forceReload: false,
};

ClubLink.propTypes = {
  club: PropTypes.shape({
    externalDomain: PropTypes.string,
    folder: PropTypes.string.isRequired,
  }),
  currentClub: PropTypes.shape({
    externalDomain: PropTypes.string,
    folder: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  route: PropTypes.string.isRequired,
  currentDomain: PropTypes.string.isRequired,
  replace: PropTypes.bool,
  params: PropTypes.shape(),
  scroll: PropTypes.bool,
  hash: PropTypes.string,
  forceReload: PropTypes.bool,
};

const mapStateToProps = (state, { currentClub }) => ({
  currentClub: currentClub || getCurrentClub(state),
  currentDomain: getHost(state),
});

export default connect(mapStateToProps)(ClubLink);
