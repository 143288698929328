import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as nativeBridge from '@nrk/nativebridge';
import { getIsApp } from 'store/common/selectors';

const AppInterceptedLink = forwardRef(
  ({ href, children, isApp, onClick: customOnClick, ...props }, ref) => {
    const onClick = (e) => {
      if (isApp) {
        e.preventDefault();
        nativeBridge.emit('new_webview', { url: `${window.location.origin}${href}` });
      } else {
        customOnClick(e);
      }
    };

    return (
      <a href={href} onClick={onClick} ref={ref} {...props}>
        {children}
      </a>
    );
  },
);

AppInterceptedLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.shape(),
  onClick: PropTypes.func,
  isApp: PropTypes.bool.isRequired,
};

AppInterceptedLink.defaultProps = {
  children: null,
  href: '',
  onClick: () => {},
};

// Because we wrapped it in forwardRef we need to re-set the display name
AppInterceptedLink.displayName = 'AppInterceptedLink';

const mapStateToProps = (state) => ({
  isApp: getIsApp(state),
});

export default connect(mapStateToProps, () => ({}), null, { forwardRef: true })(AppInterceptedLink);
