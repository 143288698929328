import { DateTime } from 'luxon';

import { get, find, head, includes, flatMap, isEmpty, orderBy } from 'lodash';

export const getClub = (state, clubId) => get(state, `clubs.clubs[${clubId}]`);

export const getCurrentClubId = (state) => get(state, 'clubs.currentClubId');

export const getCurrentThemeDraftId = (state) => get(state, 'clubs.themeDraftId');

export const loadingDraftTheme = (state) => get(state, 'clubs.loadingDraftTheme');

export const getDraftTheme = (state) => get(state, 'clubs.draftTheme');

export const isIframePreview = (state) => get(state, 'clubs.isIframePreview');

export const hasLoadedDraftTheme = (state, draftId) => {
  const loaded = get(state, 'clubs.loadedDraftThemes');
  return includes(loaded, draftId);
};

export const getCurrentClub = (state) => {
  const club = getClub(state, getCurrentClubId(state));
  const draftTheme = get(state, 'clubs.draftTheme.theme');
  if (draftTheme) {
    return {
      ...club,
      ...draftTheme,
    };
  }

  return club;
};

export const getClubTheme = (state) => get(state, 'clubs.theme', {});

export const getCurrentClubIncludesAdverts = (state) => {
  return get(getCurrentClub(state), `includeAdverts`, false);
};

export const getCurrentClubIncludesSkins = (state) => {
  return get(getCurrentClub(state), `advertisingTier`, 0) === 1;
};

export const getClubSections = (state, clubId) => {
  const club = getClub(state, clubId);
  return get(club, 'sections', []);
};
export const getClubTeams = (state, clubId) => {
  const sections = getClubSections(state, clubId);
  return flatMap(sections, (section) => section.teams);
};

export const getClubTeam = (state, clubId, teamId) => {
  const teams = getClubTeams(state, clubId);
  return find(teams, { id: teamId });
};

export const getCurrentClubSeasons = (state) => {
  const club = getCurrentClub(state);
  const seasons = get(club, 'seasons', []);
  return orderBy(seasons, ['end'], ['desc']);
};

export const getSeason = (state, id) => {
  const seasons = getCurrentClubSeasons(state);
  return find(seasons, { id });
};

export const getDefaultSeasonId = (state) => {
  const seasons = getCurrentClubSeasons(state);
  if (isEmpty(seasons)) {
    return 0;
  }

  const now = DateTime.local();
  const activeSeason = find(seasons, (season) => {
    return DateTime.fromISO(season.start) <= now && DateTime.fromISO(season.end) >= now;
  });

  if (activeSeason) {
    return activeSeason.id;
  }

  const firstSeason = head(seasons);
  return firstSeason && firstSeason.id;
};

export const getActiveSeasonId = (state) => {
  return get(state, 'clubs.activeSeasonId') || getDefaultSeasonId(state);
};
